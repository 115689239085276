import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-356f520e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "lCommon" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_as_header_mobile = _resolveComponent("as-header-mobile")!
  const _component_ac_support = _resolveComponent("ac-support")!
  const _component_as_banners_catalog = _resolveComponent("as-banners-catalog")!
  const _component_mq_responsive = _resolveComponent("mq-responsive")!
  const _component_as_breadcrumb = _resolveComponent("as-breadcrumb")!
  const _component_as_header_main = _resolveComponent("as-header-main")!
  const _component_as_menu = _resolveComponent("as-menu")!
  const _component_as_banner = _resolveComponent("as-banner")!
  const _component_as_banners_top = _resolveComponent("as-banners-top")!
  const _component_as_row = _resolveComponent("as-row")!
  const _component_as_spacer = _resolveComponent("as-spacer")!
  const _component_as_footer = _resolveComponent("as-footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_mq_responsive, {
      class: "smFaq",
      target: "mobile-tablet"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_as_header_mobile, {
          "custom-back": "",
          onGoBack: _ctx.goBack,
          title: _ctx.$t('supportCenter'),
          "onMenu:toggle": _cache[0] || (_cache[0] = ($event: any) => (_ctx.isOpenMenu = $event))
        }, null, 8, ["onGoBack", "title"]),
        _withDirectives(_createVNode(_component_ac_support, null, null, 512), [
          [_vShow, !_ctx.isOpenMenu]
        ]),
        _withDirectives(_createVNode(_component_as_banners_catalog, {
          banners: _ctx.bannersCatalog,
          position: _ctx.bannersPosition
        }, null, 8, ["banners", "position"]), [
          [_vShow, !_ctx.isOpenMenu]
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_mq_responsive, {
      class: "lCommon bg-blue",
      target: "desktop"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_as_header_main, null, {
          breadcrumb: _withCtx(() => [
            _createVNode(_component_as_breadcrumb, { title: _ctx.title }, null, 8, ["title"])
          ]),
          _: 1
        }),
        _createVNode(_component_as_row, null, {
          menu: _withCtx(() => [
            _createVNode(_component_as_menu, {
              menu: _ctx.menu,
              type: "sidebar"
            }, null, 8, ["menu"]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bannersSidebar, (banner, i) => {
              return (_openBlock(), _createBlock(_component_as_banner, {
                key: `bannerSidebar${i}`,
                banner: banner,
                platform: _ctx.bannersPlatforms.desktop,
                position: _ctx.bannersPosition,
                placement: _ctx.bannersPlacements.sidebar
              }, null, 8, ["banner", "platform", "position", "placement"]))
            }), 128))
          ]),
          content: _withCtx(() => [
            _createVNode(_component_as_banners_top, {
              banners: _ctx.bannersTop,
              position: _ctx.bannersPosition,
              placement: _ctx.bannersPlacements.top,
              platform: _ctx.bannersPlatforms.desktop
            }, null, 8, ["banners", "position", "placement", "platform"]),
            _createVNode(_component_as_row, { class: "-inner lgFaq" }, {
              content: _withCtx(() => [
                _createVNode(_component_ac_support)
              ]),
              _: 1
            }),
            _createVNode(_component_as_banners_top, {
              banners: _ctx.bannersBottom,
              position: _ctx.bannersPosition,
              placement: _ctx.bannersPlacements.bottom,
              platform: _ctx.bannersPlatforms.desktop
            }, null, 8, ["banners", "position", "placement", "platform"])
          ]),
          _: 1
        }),
        _createVNode(_component_as_spacer),
        _createVNode(_component_as_footer)
      ]),
      _: 1
    })
  ]))
}