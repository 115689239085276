import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_as_banner_square = _resolveComponent("as-banner-square")!
  const _component_as_banner_container = _resolveComponent("as-banner-container")!
  const _component_as_container = _resolveComponent("as-container")!

  return (_ctx.banners.length)
    ? (_openBlock(), _createBlock(_component_as_container, {
        key: 0,
        class: "asBannerCatalog"
      }, {
        title: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('YouMayLike')) + " ", 1),
          _createVNode(_component_router_link, {
            class: "asBannerCatalog_linkAllBanner",
            to: "/banners"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('All')), 1)
            ]),
            _: 1
          })
        ]),
        content: _withCtx(() => [
          _createVNode(_component_as_banner_container, null, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.banners, (banner) => {
                return (_openBlock(), _createBlock(_component_as_banner_square, {
                  key: banner.id,
                  position: _ctx.position,
                  placement: _ctx.placement,
                  platform: _ctx.platform,
                  banner: banner
                }, null, 8, ["position", "placement", "platform", "banner"]))
              }), 128))
            ]),
            _: 1
          })
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}