
import { defineComponent } from 'vue'

import { Form as VForm, Field as VField } from 'vee-validate'

import AsInput from '@web/components/AsInput/AsInput.vue'
import AsTextarea from '@web/components/AsTextarea.vue'
import AsNotice from '@web/components/AsNotice.vue'

import i18n from '@web/plugins/i18n'
import sentry from '@web/common/sentry'
import Swal from 'sweetalert2'

import useErrors from '@web/composition/useErrors'
import useTechQuestion from '@web/composition/useTechQuestion'
import Logger from '@web/common/Logger'
import { useStore } from '@web/store'
import AcButton from '@ui-kit/components/AcButton.vue'

export default defineComponent({
  components: {
    AcButton,
    AsInput,
    AsTextarea,
    VForm,
    VField,
    AsNotice
  },
  props: {
    title: {
      type: String,
      default: () => i18n.global.t('writeInSupport')
    },
    placeholder: {
      type: String,
      default: () => i18n.global.t('describeProblem')
    },
    label: {
      type: String,
      default: () => i18n.global.t('textAppeal')
    },
    streamId: {
      type: Number,
      default: () => null
    },
    categoryId: {
      type: Number,
      default: () => null
    },
    msg: {
      type: String,
      default: ''
    },
    btnClose: {
      type: Boolean,
      default: false
    }
  },
  emits: ['submit', 'close'],
  setup (props, ctx) {
    const store = useStore()
    const {
      serverErrors,
      getFirstError
    } = useErrors()

    const {
      courseTitle,
      notice,
      message,
      save,
      error,
      name,
      button,
      category,
      subjectTitle,
      init,
      getTargetId
    } = useTechQuestion({ streamId: props.streamId, categoryId: props.categoryId })

    message.value = props.msg

    async function submit () {
      try {
        if (message.value === '' || !save.value || !category.value) return

        save.value = false
        error.value = ''

        await store.dispatch('faq/sendTechQuestion', {
          name: name.value,
          supportCategoryId: category.value.id.toString(),
          targetId: getTargetId(),
          message: message.value
        })
        message.value = ''
        ctx.emit('submit')
        Swal.fire({
          icon: 'success',
          title: `${i18n.global.t('successfully')}!`,
          text: i18n.global.t('messageSent')
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        Logger.log('error.errors', error)
        sentry.captureException(error)
        error.value = i18n.global.t('error.cantSendMessage')
        serverErrors.value = error.errors || {
          message: [i18n.global.t('error.cantSendMessage')]
        }
        notice.value = error.errors || i18n.global.t('error.cantSendMessage')
        Logger.error(error)
      } finally {
        save.value = true
      }
    }

    function close () {
      ctx.emit('close')
    }

    init()

    return {
      courseTitle,
      notice,
      message,
      save,
      error,
      button,
      subjectTitle,
      getFirstError,
      submit,
      close
    }
  }
})
