import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-aa0ac7b0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "asTechQuestion" }
const _hoisted_2 = { class: "asTechQuestion_header" }
const _hoisted_3 = { class: "asTechQuestion_tip" }
const _hoisted_4 = { class: "asTechQuestion_error" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_as_notice = _resolveComponent("as-notice")!
  const _component_as_input = _resolveComponent("as-input")!
  const _component_as_textarea = _resolveComponent("as-textarea")!
  const _component_v_field = _resolveComponent("v-field")!
  const _component_ac_button = _resolveComponent("ac-button")!
  const _component_v_form = _resolveComponent("v-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createTextVNode(_toDisplayString(_ctx.title) + " ", 1),
      (_ctx.btnClose)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "asTechQuestion_close",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
          }))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_as_notice, {
      class: "-grey",
      notice: _ctx.notice
    }, null, 8, ["notice"]),
    _createVNode(_component_v_form, {
      class: "asTechQuestion_content",
      as: "div"
    }, {
      default: _withCtx(() => [
        (_ctx.streamId)
          ? (_openBlock(), _createBlock(_component_as_input, {
              key: 0,
              name: "stream",
              label: _ctx.$t('course'),
              type: "text",
              modelValue: _ctx.courseTitle,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.courseTitle) = $event)),
              readonly: "",
              class: "-grey"
            }, null, 8, ["label", "modelValue"]))
          : _createCommentVNode("", true),
        _createVNode(_component_as_input, {
          name: "subject",
          label: _ctx.$t('subject'),
          type: "text",
          modelValue: _ctx.subjectTitle,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.subjectTitle) = $event)),
          readonly: "",
          class: "-grey"
        }, null, 8, ["label", "modelValue"]),
        _createVNode(_component_v_field, {
          modelValue: _ctx.message,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.message) = $event)),
          modelModifiers: { trim: true },
          name: "message",
          label: _ctx.$t('message'),
          rules: "required"
        }, {
          default: _withCtx(({ errors }) => [
            _createVNode(_component_as_textarea, {
              name: "message",
              error: _ctx.getFirstError('message', errors),
              modelValue: _ctx.message,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.message) = $event)),
              modelModifiers: { trim: true },
              class: "asTechQuestion_input -grey",
              label: _ctx.label,
              placeholder: _ctx.placeholder,
              maxlength: "500",
              autofocus: ""
            }, null, 8, ["error", "modelValue", "label", "placeholder"])
          ]),
          _: 1
        }, 8, ["modelValue", "label"]),
        _createVNode(_component_ac_button, {
          class: "asTechQuestion_button",
          disabled: !_ctx.save,
          onClick: _ctx.submit,
          type: "submit"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t(_ctx.button)), 1)
          ]),
          _: 1
        }, 8, ["disabled", "onClick"]),
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('answerToEmail')), 1),
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.error), 1)
      ]),
      _: 1
    })
  ]))
}