
import { defineComponent, onBeforeMount } from 'vue'

import SupportDescription from './SupportDescription.vue'
import AcExpansionPanels from '@ui-kit/components/AcExpansionPanels.vue'
import AcExpansionPanel from '@ui-kit/components/AcExpansionPanel.vue'
import AcModal from '@ui-kit/components/AcModal.vue'
import AsTechQuestion from '@web/components/AsTechQuestion.vue'
import AsPreloader from '@web/components/AsPreloader.vue'

import useSupport from './useSupport'
import { useRouter } from 'vue-router'
import AcEmpty from '@ui-kit/components/AcEmpty.vue'

export default defineComponent({
  name: 'Support',
  components: {
    SupportDescription,
    AcEmpty,
    AcExpansionPanels,
    AcExpansionPanel,
    AcModal,
    AsPreloader,
    AsTechQuestion
  },
  setup () {
    const router = useRouter()
    const {
      init,
      ...faqData
    } = useSupport({ router })

    onBeforeMount(() => {
      init()
    })

    return {
      ...faqData
    }
  }
})
