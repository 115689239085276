
import { defineComponent, PropType, ref, computed, watch } from 'vue'

export default defineComponent({
  name: 'AcExpansionPanel',
  props: {
    panel: {
      type: Object,
      default: null
    },
    fields: {
      type: Object as PropType<{ title: string, text: string }>,
      default: () => ({ title: 'title', text: 'text' })
    },
    expandIcon: {
      type: Object as PropType<{ expand: 'chevron-down' | 'plus', collapse: 'chevron-up' | 'minus' }>,
      default: () => ({ expand: 'chevron-down', collapse: 'chevron-up' })
    },
    customToggle: {
      type: Boolean,
      default: false
    },
    isOpen: {
      type: Boolean,
      default: false
    },
    isClose: {
      type: Boolean,
      default: true
    },
    withAnimation: {
      type: Boolean,
      default: true
    }
  },
  emits: ['open', 'close'],
  setup (props, ctx) {
    const isClosePanel = ref(true)
    const icon = computed(() => isClosePanel.value ? props.expandIcon.expand : props.expandIcon.collapse)

    function togglePanel () {
      if (isClosePanel.value) {
        ctx.emit('open')
      } else {
        ctx.emit('close')
      }

      if (!props.customToggle) {
        isClosePanel.value = !isClosePanel.value
      }
    }

    watch(() => props.isClose, () => {
      if (props.isClose) {
        isClosePanel.value = true
      }
    }, {
      immediate: true
    })

    watch(() => props.isOpen, () => {
      if (props.isOpen) {
        isClosePanel.value = false
      }
    }, {
      immediate: true
    })

    return {
      isClosePanel,
      icon,
      togglePanel
    }
  }
})
