import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d7d08330"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["data-analytics-element-target", "data-analytics-banner-id", "href"]
const _hoisted_2 = { class: "tag" }
const _hoisted_3 = ["src", "alt"]
const _hoisted_4 = { class: "content" }
const _hoisted_5 = { class: "note" }
const _hoisted_6 = { class: "button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.image)
    ? (_openBlock(), _createElementBlock("a", {
        key: 0,
        class: _normalizeClass(["asBannerSquare", [`-${_ctx.position}`, `-${_ctx.placement}`, `asBanner_${_ctx.banner.id}`]]),
        "data-cy": "banner",
        "data-analytics": "",
        "data-analytics-element-type": "banner",
        "data-analytics-element-target": _ctx.url,
        "data-analytics-banner-id": _ctx.banner.id,
        href: _ctx.getUrl(_ctx.banner),
        target: "_blank",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onBannerClick && _ctx.onBannerClick(...args))),
        ref: "refBanner"
      }, [
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.banner.tag), 1),
        _createElementVNode("img", {
          src: _ctx.getImage(_ctx.banner),
          alt: _ctx.banner.title,
          class: "image",
          onLoad: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onBannerShow && _ctx.onBannerShow(...args)))
        }, null, 40, _hoisted_3),
        _withDirectives(_createElementVNode("span", _hoisted_4, [
          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.banner.subtitle), 1),
          _createElementVNode("button", _hoisted_6, _toDisplayString(_ctx.$t('More')), 1)
        ], 512), [
          [_vShow, _ctx.isShown]
        ])
      ], 10, _hoisted_1))
    : _createCommentVNode("", true)
}