
import { defineComponent } from 'vue'
import PAGE_NAME from '@web/consts/PAGE_NAME'

export default defineComponent({
  name: 'AcEmpty',
  props: {
    showButton: {
      type: Boolean,
      default: true
    },
    link: {
      Object,
      default: () => ({
        name: PAGE_NAME.HOME
      })
    }
  }
})
