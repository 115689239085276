
import { defineComponent, ref } from 'vue'

export default defineComponent({
  setup () {
    const classes = ref('')

    return {
      classes
    }
  }
})
