import { ref, computed } from 'vue'
import TargetProduct from '@web/consts/TargetProduct'
import { useStore } from '@web/store'
import i18n from '@web/plugins/i18n'

function useTechQuestion ({ streamId, categoryId }) {
  const store = useStore()

  const notice = ref<string | string[]>('')
  const message = ref('')
  const save = ref(true)
  const error = ref('')
  const user = computed(() => store.getters['user/user'])
  const name = computed(() => {
    let username = `${user.value.lastName || ''} ${user.value.firstName || ''}`.trim()
    username += ` <${user.value.email}> `
    username += `id ${user.value.id}`
    return username
  })
  const button = computed(() => save.value ? 'send' : 'sending')
  const category = computed(() => store.getters['faq/getCategoryById'](categoryId))
  const subjectTitle = computed(() => category.value ? category.value.title : i18n.global.t('otherIssue'))
  const stream = computed(() => store.getters['stream/getStreamById'](streamId))
  const course = computed(() => stream.value ? store.getters['course/getCourseById'](stream.value.courseId) : undefined)
  const courseTitle = computed(() => course.value?.title || '')

  function getTargetId () {
    if (category.value?.targetProduct === TargetProduct.USER_COURSE) {
      return streamId
    }
    return null
  }

  function init () {
    store.dispatch('stream/fetchUserCourseStreamsCached')
    store.dispatch('faq/fetchCategoriesCached')
  }

  return {
    stream,
    course,
    courseTitle,
    notice,
    message,
    save,
    error,
    user,
    name,
    button,
    category,
    subjectTitle,
    getTargetId,
    init
  }
}

export default useTechQuestion
