import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "acExpansionPanel_title__icon" }
const _hoisted_2 = { class: "acExpansionPanel_text__wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["acExpansionPanel", {
      '-withAnimation': _ctx.withAnimation
    }])
  }, [
    _createElementVNode("div", {
      class: "acExpansionPanel_title",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.togglePanel && _ctx.togglePanel(...args)))
    }, [
      _renderSlot(_ctx.$slots, "title", {}, () => [
        _createTextVNode(_toDisplayString(_ctx.panel[_ctx.fields.title]), 1)
      ]),
      _createElementVNode("div", _hoisted_1, [
        (_ctx.isClosePanel)
          ? _renderSlot(_ctx.$slots, "icon-expand", { key: 0 }, () => [
              _createElementVNode("i", {
                class: _normalizeClass(`icon icon-${_ctx.icon}`)
              }, null, 2)
            ])
          : _renderSlot(_ctx.$slots, "icon-collapse", { key: 1 }, () => [
              _createElementVNode("i", {
                class: _normalizeClass(`icon icon-${_ctx.icon}`)
              }, null, 2)
            ])
      ])
    ]),
    _createVNode(_Transition, { name: "slide" }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: _normalizeClass(["acExpansionPanel_text", {
          '-isClosePanel': _ctx.isClosePanel
        }])
        }, [
          _createElementVNode("div", _hoisted_2, [
            _renderSlot(_ctx.$slots, "text", {}, () => [
              _createTextVNode(_toDisplayString(_ctx.panel[_ctx.fields.text]), 1)
            ])
          ])
        ], 2)
      ]),
      _: 3
    })
  ], 2))
}