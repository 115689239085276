import { ref, computed, onBeforeUnmount, watch } from 'vue'
import { useStore } from '@web/store'
import Swal from 'sweetalert2'
import TargetProduct from '@web/consts/TargetProduct'
import sentry from '@web/common/sentry'
import Logger from '@web/common/Logger'
import { IS_MOBILE } from '@web/common/config'
import isEmpty from 'lodash.isempty'
import { Stream } from '@web/store/types/modules/stream'
import { Router } from 'vue-router'
import i18n from '@web/plugins/i18n'
import Analytics from '@web/services/Analytics/Analytics'
import StreamStatus from '@web/consts/StreamStatus'
import { getNoteCountDayRemaining } from '@web/common/Utils'
import PAGE_NAME from '@web/consts/PAGE_NAME'

export default function ({ router }: { router: Router }) {
  const store = useStore()
  const loading = ref<boolean>(false)
  const selectedId = computed<number | null>(() => Number(router.currentRoute.value.params?.categoryId) || null)
  const selectedStreamId = computed<number | null>(() => Number(router.currentRoute.value.params?.streamId) || null)
  const showTechQuestion = ref(false)
  const autoResolveBtnDisabled = ref(false)

  const user = computed(() => store.getters['user/user'])
  const username = computed(() => store.getters['user/username'])
  const categories = computed(() => store.getters['faq/categories'])
  const courses = computed(() => store.getters['course/courses'])
  const streams = computed(() => store.getters['stream/streams'])
  const returnUrl = computed(() => store.getters['router/returnUrl'])
  const history = computed(() => store.getters['router/history'])
  const selectedCategory = computed(() => categories.value.find(({ id }) => id === selectedId.value))
  const title = i18n.global.t('supportCenter')
  const name = computed(() => {
    let username = `${user.value.lastName || ''} ${user.value.firstName || ''}`.trim()
    username += ` <${user.value.email}> `
    username += `id ${user.value.id}`
    return username
  })

  const userCourseStreams = computed<{ stream: Stream; course: Course }[]>(() => {
    return Object.values(streams.value).map((stream: Stream) => {
      const course = { ...courses.value[stream.courseId] }
      if (stream.status !== StreamStatus.OPEN) {
        course.title = `<div class="title">${course.title}</div><div class="subTitle">(${getNoteCountDayRemaining(stream).toLowerCase()})</div>`
      }
      return {
        stream,
        course
      }
    })
      .sort((a, b) => {
        const streamPrev: Stream = a.stream
        const streamNext: Stream = b.stream
        const order = [StreamStatus.OPEN, StreamStatus.NOT_STARTED, StreamStatus.FREEZE, StreamStatus.STOPPED, StreamStatus.ENDED]
        return order.indexOf(streamPrev.status) - order.indexOf(streamNext.status)
      })
  })

  const selectedStream = computed(() => userCourseStreams.value.find(s => s.stream.id === selectedStreamId.value))

  const showSelectionStream = computed(() => {
    return selectedCategory.value?.requireProduct
  })

  const isSelectedCategory = computed(() => !!selectedId.value)

  function setPageData () {
    Analytics.clearPageData()
    let params: Dictionary<string> = {}
    if (selectedStream.value) {
      params = {
        courseTitle: selectedStream.value.course.title,
        courseId: String(selectedStream.value.course.id),
        streamId: String(selectedStream.value.stream.streamId),
        userCourseStreamId: String(selectedStream.value.stream.id),
        startDate: String(selectedStream.value.stream.startDate),
        endDate: String(selectedStream.value.stream.endDate)
      }
    }
    if (selectedCategory.value) {
      params = {
        ...params,
        allowAutoResolve: String(selectedCategory.value.allowAutoResolve),
        sort: String(selectedCategory.value.sort),
        requireProduct: String(selectedCategory.value.requireProduct),
        targetProduct: String(selectedCategory.value.targetProduct),
        categoryId: String(selectedCategory.value.id),
        categoryName: selectedCategory.value.name,
        categoryTitle: selectedCategory.value.title
      }
    }
    Analytics.setPageData(params)
  }

  function setContent (id: number) {
    if (selectedId.value !== id) {
      router.replace({
        name: 'support-category',
        params: {
          categoryId: String(id)
        }
      })
    }
  }

  function setStreamId (streamId: number | null) {
    if (selectedStreamId.value !== streamId && streamId) {
      router.replace({
        name: 'support-category-stream',
        params: {
          categoryId: String(selectedId.value),
          streamId: String(streamId)
        }
      })
    } else {
      router.replace({
        name: 'support-category',
        params: {
          categoryId: String(selectedId.value)
        }
      })
    }
  }

  function showTech () {
    if (IS_MOBILE) {
      router.push({
        name: 'tech-question',
        params: {
          categoryId: String(selectedId.value),
          streamId: String(selectedStreamId.value)
        }
      })
    } else {
      showTechQuestion.value = true
    }
  }

  function onSubmitTechQuestion () {
    Analytics.send({
      category: 'support_activity',
      action: 'support_send_question',
      auto_resolve: 'false'
    })
    showTechQuestion.value = false
  }

  function getTargetId () {
    if (selectedCategory.value?.targetProduct === TargetProduct.USER_COURSE) {
      return selectedStreamId.value
    }
    return null
  }

  function autoResolveHandler () {
    if (selectedCategory.value) {
      autoResolveBtnDisabled.value = true
      store.dispatch('faq/sendTechQuestionAuto', {
        name: name.value,
        supportCategoryId: selectedCategory.value.id.toString(),
        targetId: getTargetId()
      }).then(() => {
        Swal.fire({
          icon: 'success',
          title: `${i18n.global.t('successfully')}!`,
          text: i18n.global.t('autoResolveAnswer'),
          confirmButtonText: i18n.global.t('close')
        })
      }).catch(e => {
        sentry.captureException(e)
        Swal.fire({
          icon: 'error',
          title: i18n.global.t('Error'),
          text: i18n.global.t('contactToSupport'),
          showCloseButton: true,
          showCancelButton: true,
          confirmButtonText: i18n.global.t('writeInSupport'),
          cancelButtonText: i18n.global.t('close')
        }).then((result) => {
          if (result.value) {
            showTech()
          }
        })
      }).finally(() => {
        Analytics.send({
          category: 'support_activity',
          action: 'support_send_question',

          auto_resolve: 'true'
        })
        autoResolveBtnDisabled.value = false
      })
    }
  }

  function isActiveCategory (id) {
    return selectedId.value === id
  }
  function isActiveStream (id) {
    return selectedStreamId.value === id
  }

  function IconRightArrowColor (type, id) {
    let selected = false
    if (!isSelectedCategory.value) {
      selected = true
    }
    if (type === 'category') {
      selected = isActiveCategory(id)
    }
    if (type === 'stream') {
      selected = isActiveStream(id)
    }
    return selected ? '#4C5264' : '#8898B1'
  }

  function goBack (backLink) {
    if (selectedStreamId.value) {
      setPageData()
      router.replace({
        name: 'support-category',
        params: {
          categoryId: String(selectedId.value)
        }
      })
    } else if (selectedCategory.value) {
      setPageData()
      router.replace({ name: 'support' })
    } else if (returnUrl.value) {
      const url = returnUrl.value
      store.commit('router/setReturnUrl', '')
      router.push(url)
    } else if (backLink) {
      router.push(backLink)
    } else {
      isEmpty(history) ? router.push({ name: PAGE_NAME.HOME }) : router.go(-1)
    }
  }

  async function init () {
    try {
      loading.value = true
      await store.dispatch('stream/fetchUserCourseStreamsCached')
      await store.dispatch('faq/fetchCategoriesCached')
    } catch (e) {
      Logger.error(e)
    } finally {
      loading.value = false
    }
  }

  onBeforeUnmount(() => {
    Analytics.clearPageData()
  })

  watch(selectedCategory, (newVal, oldVal) => {
    if (newVal && newVal?.id !== oldVal?.id) {
      setPageData()
      Analytics.send({
        category: 'support_activity',
        action: 'open_support_category'
      })
    }
  })

  watch(selectedStreamId, (newVal, oldVal) => {
    if (newVal && newVal !== oldVal) {
      setPageData()
      Analytics.send({
        category: 'support_activity',
        action: 'open_support_category_course'
      })
    }
  }, {
    immediate: true
  })

  return {
    isSelectedCategory,
    loading,
    selectedStreamId,
    selectedStream,
    title,
    categories,
    user,
    username,
    courses,
    userCourseStreams,
    streams,
    selectedId,
    selectedCategory,
    showTechQuestion,
    autoResolveBtnDisabled,
    showSelectionStream,
    setContent,
    setStreamId,
    showTech,
    onSubmitTechQuestion,
    IconRightArrowColor,
    init,
    goBack,
    autoResolveHandler,
    isActiveCategory,
    isActiveStream
  }
}
