
import { defineComponent } from 'vue'

import AsContainer from '@web/components/AsContainer.vue'
import AsBannerContainer from '@web/components/AsBannerContainer.vue'
import AsBannerSquare from '@web/components/AsBannerSquare.vue'
import { Placement, Platform } from '@web/mapper/BannerMapper'

export default defineComponent({
  name: 'AsBannersTop',
  props: {
    banners: {
      type: Object as () => Banner[],
      required: true
    },
    position: {
      type: String,
      required: true
    },
    placement: {
      type: String,
      default: Placement.catalog
    },
    platform: {
      type: String,
      default: Platform.mobile
    }
  },
  components: {
    AsContainer,
    AsBannerContainer,
    AsBannerSquare
  }
})
