
import { defineComponent, PropType } from 'vue'

import AcButton from '@ui-kit/components/AcButton.vue'

import { Category } from '@web/store/types/modules/faq'

export default defineComponent({
  name: 'SupportDescription',
  components: {
    AcButton
  },
  props: {
    category: {
      type: Object as PropType<Category>,
      required: true
    },
    autoResolveBtnDisabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['autoResolve', 'showTech'],
  setup (props, ctx) {
    function autoResolveHandler () {
      ctx.emit('autoResolve')
    }

    function showTechHandler () {
      ctx.emit('showTech')
    }

    return {
      autoResolveHandler,
      showTechHandler
    }
  }
})
