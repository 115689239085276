
import { defineComponent } from 'vue'
import useBanner from '@web/composition/useBanner'

export default defineComponent({
  name: 'asBannerSquare',
  props: {
    banner: {
      type: Object as () => Banner,
      required: true
    },
    position: {
      type: String,
      default: ''
    },
    placement: {
      type: String,
      default: ''
    },
    platform: {
      type: String,
      default: ''
    }
  },
  setup (props) {
    return useBanner({
      banner: props.banner,
      position: `${props.platform}_${props.position}_${props.placement}`,
      width: 320
    })
  }
})
