import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "asRow" }
const _hoisted_2 = { class: "asRow_col -menu" }
const _hoisted_3 = { class: "asRow_col -content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "menu")
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["asRow_col -sidebar", _ctx.classes])
    }, [
      _renderSlot(_ctx.$slots, "sidebar")
    ], 2),
    _createElementVNode("div", _hoisted_3, [
      _renderSlot(_ctx.$slots, "content")
    ])
  ]))
}