
import { ref, computed, defineComponent, onBeforeMount } from 'vue'
import { MqResponsive } from 'vue3-mq'
import isEmpty from 'lodash.isempty'

import AsMenu from '@web/components/AsMenu.vue'
import AsRow from '@web/components/AsRow.vue'
import AsHeaderMain from '@web/components/headers/AsHeaderMain.vue'
import AsFooter from '@web/components/AsFooter.vue'
import AsSpacer from '@web/components/AsSpacer.vue'
import AsBreadcrumb from '@web/components/AsBreadcrumb.vue'
import AsBanner from '@web/components/AsBanner.vue'
import AsBannersCatalog from '@web/components/AsBannersCatalog.vue'
import AsBannersTop from '@web/components/AsBannersTop.vue'
import AsHeaderMobile from '@web/components/headers/AsHeaderMobile.vue'

import AcSupport from '@web/components.v2/Support/index.vue'

import { useSupportBanners } from '@web/composition/useBanners'
import { useMeta } from '@web/common/vueMeta'
import { useRouter } from 'vue-router'
import { useStore } from '@web/store'
import i18n from '@web/plugins/i18n'
import PAGE_NAME from '@web/consts/PAGE_NAME'

export default defineComponent({
  components: {
    MqResponsive,
    AsHeaderMobile,
    AsMenu,
    AsRow,
    AsHeaderMain,
    AsSpacer,
    AsFooter,
    AsBreadcrumb,
    AsBanner,
    AsBannersCatalog,
    AsBannersTop,

    AcSupport
  },
  setup () {
    const router = useRouter()
    const store = useStore()

    const isOpenMenu = ref(false)

    useMeta({
      title: i18n.global.t('supportCenter')
    })

    const menu = computed(() => store.getters['menu/general'])
    const categoryId = computed<number | null>(() => Number(router.currentRoute.value.params?.categoryId) || null)
    // const streamId = computed<number | null>(() => Number(router.currentRoute.value.params?.streamId) || null)
    const returnUrl = computed(() => store.getters['router/returnUrl'])

    function goBack (backLink) {
      if (categoryId.value) {
        // setPageData()
        router.replace({ name: 'support' })
      } else if (returnUrl.value) {
        const url = returnUrl.value
        store.commit('router/setReturnUrl', '')
        router.push(url)
      } else if (backLink) {
        router.push(backLink)
      } else {
        isEmpty(history) ? router.push({ name: PAGE_NAME.HOME }) : router.go(-1)
      }
    }

    onBeforeMount(() => {
      // const vh = window.innerHeight * 0.01
      // document.documentElement.style.setProperty('--vh', `${vh}px`)
      //
      // window.addEventListener('resize', () => {
      //   const vh = window.innerHeight * 0.01
      //   document.documentElement.style.setProperty('--vh', `${vh}px`)
      // })
    })

    return {
      menu,
      title: i18n.global.t('supportCenter'),
      isOpenMenu,
      goBack,
      ...useSupportBanners()
    }
  }
})
